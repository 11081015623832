import React, { ChangeEvent } from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  Grid,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Backdrop,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import { connect } from "react-redux";
import Termscondition from "../../elements/public/term-condition";
import { register } from "../../services/auth.service";
import { setSnackbarMessage } from "../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../store/UIActions/Snackbar/model";
import {
  urlRegex,
  usernameRegex,
  passwordRegex,
} from "../../services/models/patterns.regex";

class Register extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAgree = this.handleAgree.bind(this);
    this.handleChangeInstructor = this.handleChangeInstructor.bind(this);
    this.addToProfile = this.addToProfile.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.validateUrl = this.validateUrl.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      phone: "",
      password: "",
      repassword: "",
      loading: false,
      successful: false,
      agree: false,
      opendialog: false,
      instructor: false,
      profileURL: "",
      profile: "",
      TC_URL: process.env.REACT_APP_TC,
    };
  }

  handleClose() {
    this.setState({
      opendialog: false,
    });
  }
  handleAgree() {
    this.setState({
      opendialog: false,
      agree: true,
    });
  }

  handleChangeInstructor(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      instructor: e.target.checked,
    });
  }

  handleChange(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const { agree } = this.state;

    const target = e.target as HTMLInputElement;

    if (agree) {
      this.setState({
        [target.name]: target.checked,
      });
    } else {
      this.setState({
        opendialog: true,
      });
    }
  }

  handleTextChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  validateUrl(): boolean {
    return urlRegex.test(this.state.profileURL);
  }

  validateUsername(): boolean {
    return usernameRegex.test(this.state.username);
  }
  // TODO add interface
  addToProfile(e: any) {
    if (this.validateUrl()) {
      this.setState({
        profile: this.state.profile + this.state.profileURL + "\n",
        profileURL: "",
      });
    } else {
      console.log(this.state.profileURL);
      if (this.state.profileURL !== "") {
        this.props.dispatch(
          setSnackbarMessage({
            type: AlertTypes.WARNING,
            showSnackBar: true,
            message: `Invalid URL - you may need to include http:// or https://`,
          })
        );
      }
    }
  }

  handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
    setTimeout(() => this.addToProfile(e), 0);
  }

  handleEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.addToProfile(e);
    }
  }

  // TODO add interface
  async handleRegister(e: any) {
    e.preventDefault();

    this.setState({
      successful: false,
      loading: true,
    });

    if (this.state.password !== this.state.repassword) {
      this.setState({
        loading: false,
      });
      this.props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.WARNING,
          showSnackBar: true,
          message: `Passwords do not match.`,
        })
      );
      return;
    }
    if (this.state.profile === "" && this.state.instructor) {
      this.setState({
        loading: false,
      });

      this.props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.WARNING,
          showSnackBar: true,
          message: `As you are requesting instructor access, you need to include a URL showing your academic profile for evaluation.`,
        })
      );
      return;
    }

    if (!this.validateUsername()) {
      this.setState({
        loading: false,
      });
      this.props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.WARNING,
          showSnackBar: true,
          message: `Invalid username. Usernames must be at least 6 characters and can only contain letters, numbers, and underscores.`,
        })
      );
      return;
    }

    if (this.state.agree) {
      try {
        await register(
          this.state.firstName,
          this.state.lastName,
          this.state.username,
          this.state.email,
          this.state.phone,
          this.state.password,
          this.state.instructor,
          this.state.profile
        );
        this.setState({
          successful: true,
        });
      } catch (error) {
        this.setState({
          successful: false,
        });
      }
    } else {
      this.props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.WARNING,
          showSnackBar: true,
          message: `Check the terms and condition checkbox.`,
        })
      );
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { message } = this.props;
    const { successful } = this.state;
    return (
      <Container component="main" maxWidth="sm" sx={{ mb: 4, pl: 8 }}>
        <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Box
            sx={{
              my: 0,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, bgcolor: "secondary.main", width: 56, height: 56 }}
            >
              {successful ? <DoneIcon /> : <LockOutlinedIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            {successful && (
              <>
                <Typography component="h2" variant="h5">
                  {this.state.profile === "" && !this.state.instructor ? (
                    <Typography component="h2" variant="subtitle2">
                      Congratulations! Your sign-up was successful. Please
                      check your email (including your spam folder) to confirm
                      your registration.
                    </Typography>
                  ) : (
                    <Typography component="h2" variant="subtitle2">
                     Congratulations! Your sign-up was successful. Please
                      wait while we review your academic profile. We will notify
                      you via email.
                    </Typography>
                  )}
                 
                </Typography>
              </>
            )}
          </Box>
          {!successful && (
            <Grid
              container
              spacing={3}
              component="form"
              onSubmit={this.handleRegister}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First name"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={this.handleTextChange}
                  value={this.state.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={this.handleTextChange}
                  value={this.state.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="username"
                  name="username"
                  label="Username"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={this.handleTextChange}
                  value={this.state.username}
                  inputProps={{ pattern: usernameRegex.source }}
                  helperText="6+ characters, only letters, digits, and _"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={this.handleTextChange}
                  value={this.state.email}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  required
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  fullWidth
                  variant="standard"
                  margin="normal"
                  onChange={this.handleTextChange}
                  value={this.state.phoneNumber}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  // value={this.state.password}
                  onChange={this.handleTextChange}
                  value={this.state.password}
                  variant="standard"
                  inputProps={{ pattern: passwordRegex.source }}
                  helperText="Password must be at least 8 characters and involve at least one of each of the following: uppercase letter, lowercase letter, a number, and a special character (!@$%&)."
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="repassword"
                  label="Confirm Password"
                  type="password"
                  id="repassword"
                  // value={this.state.password}
                  onChange={this.handleTextChange}
                  value={this.state.repassword}
                  variant="standard"
                  inputProps={{ pattern: passwordRegex.source }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  // sx={{ ml: 0 }}
                  value="instructor"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={this.handleChangeInstructor}
                      checked={this.state.instructor}
                    />
                  }
                  label="Request Instructor Access"
                  // labelPlacement="start"
                />
              </Grid>
              {this.state.instructor && (
                <>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      aria-label="Institutional profile"
                      minRows={7}
                      placeholder="Institutional profile"
                      disabled
                      value={this.state.profile}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Institutional profile URL (to verify employment)."
                      variant="standard"
                      id="profileURL"
                      name="profileURL"
                      value={this.state.profileURL}
                      onChange={this.handleTextChange}
                      onPaste={(event: any) => this.handlePaste(event)}
                      onKeyDown={(event: any) => this.handleEnter(event)}
                      onBlur={this.addToProfile}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={this.addToProfile}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.agree}
                      onClick={this.handleChange}
                      name="agree"
                    />
                  }
                  label={
                    <Box component="span">
                      By clicking here, I state that I have read and understood
                      the
                      <Typography
                        variant="button"
                        component="a"
                        href="/legal/termsandconditions"
                        target="_blank"
                        style={{ marginLeft: "4px" }}
                      >
                        Terms and Conditions
                      </Typography>
                      .
                    </Box>
                  }
                />
              </Grid>

              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={this.state.loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              {this.state.msg && (
                <Grid item xs={12}>
                  <Alert severity="error">{this.state.msg}</Alert>
                </Grid>
              )}
              {message && (
                <Grid item xs={12}>
                  <Alert severity="error">{message}</Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <span>Register</span>
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
        <Dialog
          open={this.state.opendialog}
          // onClose={handleClose}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="scroll-dialog-title">
            Terms and Conditions
          </DialogTitle>
          <DialogContent dividers sx={{ height: "75vh" }}>
            {this.state.TC_URL ? (
              <iframe
                style={{ width: "95%", height: "80%" }}
                id="docs-online-viewer"
                title="student Guide"
                src={this.state.TC_URL}
              ></iframe>
            ) : (
              <>
                <Termscondition />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleAgree}>Agree</Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

function mapStateToProps(state: any) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(Register);
