/* eslint-disable import/no-anonymous-default-export */
import {
  ActionType,
  CREATE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_SUCCESS,
  DELETE_STUDENT_SUCCESS,
  GET_CLASS_DETAILS_SUCCESS,
  UPDATE_ASSIGNMENT_STOP_SUCCESS,
  UPDATE_ASSIGNMENT_SUCCESS,
  UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS,
} from "./actions";
import { ClassDetails, Student, initialState } from "./model";

export default (state = initialState, action: ActionType): ClassDetails => {
  const { type } = action;

  switch (type) {
    case GET_CLASS_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload.classDetails,
      };
    case DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ASSIGNMENT_STOP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CREATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        students: [
          ...state.students.filter(
            (student: Student) => student.id !== action.payload.studentId
          ),
        ],
      };
    default:
      return state;
  }
};
