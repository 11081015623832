import React, { useRef, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import {
  SubmissionDetails,
  FeedBack,
} from "../../../../services/models/assignments.model";
import FeedbackItem from "./FeedbackItem";
import { TextareaAutosize } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  addFeedbackAction,
  updateFeedbacksAction,
} from "../../../../store/ReadAssignment/actions";

interface FeedbackProps {
  open: boolean;
  submissionId: string;
  onClose: () => void;
}

const Feedback: React.FC<FeedbackProps> = ({ open, submissionId, onClose }) => {
  const dispatch = useDispatch();
  const submissionData: SubmissionDetails = useSelector(
    (state: any) => state.assignment.submissionData
  );

  const [disabled, setDisabled] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [enableSend, setEnableSend] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollToSection = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };

  const handleDelete = async (index: number) => {
    try {
      let feedbacks: FeedBack[] = submissionData.feedbacks;
      feedbacks = feedbacks.filter((_, i) => i !== index);
      // @ts-ignore
      dispatch(updateFeedbacksAction(submissionId, feedbacks));
    } catch (error) {}
  };

  const handleUpdate = async (index: number, editedcomment: string) => {
    try {
      const feedbacks: FeedBack[] = submissionData.feedbacks;
      const newfeedbacks = feedbacks.map((e, i) => {
        if (i === index) {
          return { ...e, comment: editedcomment };
        }
        return e;
      });
      // console.log(newfeedbacks);
      // @ts-ignore
      dispatch(updateFeedbacksAction(submissionId, newfeedbacks));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSend = async () => {
    try {
      // @ts-ignore
      dispatch(addFeedbackAction(submissionId, comment));
      setComment("");
    } catch (error) {}
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        scrollToSection();
      }, 100); // Adjust the delay as needed
    }
  }, [open, submissionData]);

  useEffect(() => {
    if (comment.length === 0 || comment.length > 5000) {
      setEnableSend(false);
    } else {
      setEnableSend(true);
    }
  }, [comment]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: "80%",
          height: "80%",
          margin: 0,
          padding: 0,
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Button onClick={onClose} startIcon={<CloseIcon />}></Button>
        Feedback Dialog
      </DialogTitle>
      <DialogContent dividers>
        <div
          ref={scrollContainerRef}
          style={{ maxHeight: "100%", overflow: "auto" }}
        >
          {submissionData.feedbacks.map((row: FeedBack, index: number) => (
            <FeedbackItem
              key={index}
              itemKey={index}
              feedback={row}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              disabled={disabled}
              setDisabled={setDisabled}
            ></FeedbackItem>
          ))}
          <div id="endofcomments" />
        </div>
      </DialogContent>
      <DialogActions>
        <TextareaAutosize
          minRows={4}
          placeholder="Feedback"
          onChange={(event) => setComment(event.target.value)}
          value={comment}
        ></TextareaAutosize>
        <Button
          onClick={handleSend}
          disabled={!enableSend}
          startIcon={<SendIcon />}
          sx={{ alignSelf: "flex-end" }}
        ></Button>
      </DialogActions>
    </Dialog>
  );
};

export default Feedback;
