import React from "react";
import { connect } from "react-redux";
import Main from "../elements/public/main";
import {
  Avatar,
  Card,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
  Container,
  CardContent,
} from "@mui/material";
import {  validate } from "../actions/auth";

// TODO add interface
class Profile extends React.Component<any, any> {
  render() {
    const { user: currentUser } = this.props;

    this.props.dispatch(validate());

    return (
      <Main>
        <Grid container component="main" sx={{ height: "80vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={12}
            md={12}
            sx={{
              backgroundImage: "url(" + process.env.PUBLIC_URL + "/bg-2.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Grid container>
              <Grid item sm={6} md={4}>
                <Container
                  sx={{
                    color: "white",
                    position: "relative",
                    top: "50%",
                    transform: "translateY(-12%)",
                  }}
                >
                  <Card sx={{ maxWidth: 345 }}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe">
                          {currentUser.firstName.substring(0, 1)}
                        </Avatar>
                      }
                      title="Profile"
                      subheader={
                        currentUser.firstName + " " + currentUser.lastName
                      }
                    />

                    <CardContent>
                      <Typography variant="body2">
                        Username: {currentUser.username}
                      </Typography>
                      <Typography variant="body2">
                        Email: {currentUser.email}
                      </Typography>
                    </CardContent>
                  </Card>
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Main>
    );
  }
}
// TODO add interface
function mapStateToProps(state: any) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Profile);
