import React, { useEffect, useRef, useState } from "react";
import { deadline, timeline } from "../model";
import { dateFormat } from "../service";
import { useTheme } from "@mui/material";
import { deriveTimelineTheme } from "react-svg-timeline";
import CustomizedTimeline from "./CustomizedTimeline";
import { ExampleEvent } from "../../../instructor/ReadAssignment/ActivityTimeLine/model";

interface ActivityTimeLineProps {
  deadlines: deadline[];
  timelines: timeline[];
  startDate: Date;
  submissionId: string;
  setFileId: (fileId: string) => void;
}

const ActivityTimeLine: React.FC<ActivityTimeLineProps> = ({
  startDate,
  deadlines,
  timelines,
  submissionId,
  setFileId,
}) => {
  const defaultTheme = useTheme();
  const theme = deriveTimelineTheme(defaultTheme.palette.mode, defaultTheme);
  const SUCCESS = defaultTheme.palette.success.dark;
  const WARNING = defaultTheme.palette.warning.dark;
  const MISSED = defaultTheme.palette.error.dark;
  const NUTREAL = defaultTheme.palette.text.secondary;
  const OOGOING = defaultTheme.palette.secondary.light;

  const [events, setEvents] = useState<any[]>([]);
  const handleTimelineClick = (eventId: string) => {
    if (eventId.includes("-")) {
      const fileId = eventId.split("-")[1];
      setFileId(fileId);

      var updatedData = events.map((item) =>
        "isPinned" in item ? { ...item, isPinned: false } : item
      );
      updatedData = updatedData.map((item) =>
        "isPinned" in item && item.eventId === eventId
          ? { ...item, isPinned: true }
          : item
      );
      setEvents(updatedData);
    }
  };

  const LANES = {
    deadlines: "deadlines",
    submissions: "submissions",
    comments: "comments",
  };
  const lanes = [
    {
      laneId: LANES.deadlines,
      label: "Drafts",
    },
    { laneId: LANES.submissions, label: "Submissions" },
    { laneId: LANES.comments, label: "Comments" },
  ];
  const containerRef = useRef<HTMLDivElement>(null);
  const [timelineWidth, setTimelineWidth] = useState<number>(0);

  const onGoingDate = (): number => {
    let maxDate = new Date(0);
    deadlines.forEach((item) => {
      const dt = new Date(item.draftDeadline);
      if (dt > maxDate) maxDate = dt;
    });
    timelines.forEach((item) => {
      const dt = new Date(item.submitDate);
      if (dt > maxDate) maxDate = dt;
      item.commentFiles.forEach((com) => {
        const dtt = new Date(com.commentDate);
        if (dtt > maxDate) maxDate = dtt;
      });
    });
    // if(new Date(startDate)>maxDate) {maxDate = new Date(startDate)};
    maxDate.setDate(maxDate.getDate() + 1);
    return new Date() > maxDate ? maxDate.getTime() : new Date().getTime();
  };

  useEffect(() => {
    if (containerRef.current) {
      // Now it's safe to access properties of the current ref
      const width = containerRef.current.clientWidth;
      setTimelineWidth(width);
    }
  }, []);
  useEffect(() => {
    const getStatusColor = (status: string): string => {
      switch (status) {
        case "MISSED":
          return MISSED;
        case "SUBMITTED_LATE":
          return WARNING;
        case "SUBMITTED_INSTRUCTOR":
          return WARNING;
        case "SUBMITTED_EXCEPTION":
          return WARNING;
        case "SUBMITTED":
          return SUCCESS;
        case "SUBMITTED_AUTO":
          return SUCCESS;
        default:
          return NUTREAL;
      }
    };
    function configEvents() {
      let newDeadlineEvents: any[] = [];
      if (deadlines.length > 0) {
        for (let i = 0; i < deadlines.length; i++) {
          let startDateTmp: number = new Date(startDate).getTime();
          if (i === 0) {
            startDateTmp = new Date(startDateTmp).getTime();
          } else {
            startDateTmp =
              new Date(deadlines[i - 1].draftDeadline).getTime() + 10;
          }
          let endDate: number = new Date(deadlines[i].draftDeadline).getTime();
          const event = {
            eventId: deadlines[i].draftId,
            tooltip: `${deadlines[i].draftTitle}\n${dateFormat(
              startDateTmp
            )}\n${dateFormat(endDate)}`,
            laneId: LANES.deadlines,
            startTimeMillis: startDateTmp,
            endTimeMillis: endDate,
            
          };
          newDeadlineEvents.push(event);
        }
      }
      if (timelines.length > 0) {
        timelines
          .filter((tl) => tl.submitDate)
          .forEach((ftl, index) => {
            let event = {
              eventId: `SB-${ftl.fileId}`,
              tooltip: `${ftl.title}\n${dateFormat(
                new Date(ftl.submitDate).getTime()
              )}`,
              laneId: LANES.submissions,
              startTimeMillis: new Date(ftl.submitDate).getTime(),
              color: getStatusColor(ftl.status),
              isPinned: false,
            };
            if (index === 0) {
              event = { ...event, eventId: "start" };
            }
            newDeadlineEvents.push(event);
            ftl.commentFiles.forEach((cm) => {
              let comment = {
                eventId: `CM-${cm.fileId}`,
                tooltip: `${ftl.title}\n${dateFormat(
                  new Date(cm.commentDate).getTime()
                )}`,
                laneId: LANES.comments,
                startTimeMillis: new Date(cm.commentDate).getTime(),
                color: SUCCESS,
                isPinned: false,
              };
              newDeadlineEvents.push(comment);
            });
          });
      }
      if (submissionId !== "") {
        let comment: ExampleEvent = {
          eventId: `OG-${submissionId}`,
          tooltip: `Ongoing`,
          laneId: LANES.deadlines,
          startTimeMillis: onGoingDate(),
          color: OOGOING,
          isPinned: true,
        };
        newDeadlineEvents.push(comment);
      }
      setEvents([...newDeadlineEvents]);
    }

    configEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines, timelines, submissionId]);

  return (
    <div ref={containerRef}>
      <CustomizedTimeline
        width={timelineWidth}
        height={140}
        events={events}
        lanes={lanes}
        dateFormat={dateFormat}
        theme={theme}
        onEventClick={(eventId) => {
          handleTimelineClick(eventId);
        }}
      />
    </div>
  );
};

export default ActivityTimeLine;
