import React, { useEffect, useState } from "react";
import Main from "../../../elements/public/main";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { validate } from "../../../actions/auth";
import { getClassesList } from "../utils/service";
import { ClassListItem } from "../utils/model";
import ClassGrid from "./classgrid";

const ClassList: React.FC = () => {
  const dispatch = useDispatch();
  const userRoles: string[] | undefined = useSelector<
    any,
    string[] | undefined
  >((state) => state.auth.user?.roles);

  const [data, setData] = useState<ClassListItem[]>();
  // Check if the user is not logged in or roles are not available
  useEffect(() => {
    // Dispatch the validate action when the component mounts
    // @ts-ignore
    dispatch(validate());

    // Fetch data asynchronously and update the state
    const fetchData = async() => {
      try {
        const response = await getClassesList();
        setData(response.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    }

    fetchData();
  }, [dispatch]);
  // @ts-ignore
  const isEduCoordinator: boolean = userRoles.includes(
    "ROLE_EDUCATION_COORDINATOR"
  );

  if (!isEduCoordinator) {
    return (
      <Main>
        <div>You don't have access to this page.</div>
      </Main>
    );
  }
  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={9} lg={10} my={1}>
          <Typography variant="h3">Class List</Typography>
        </Grid>
      </Grid>
      <>
       { data && (<ClassGrid data={data} />) }
      </>
    </Main>
  );
};

export default ClassList;
