// rewindReducer.ts
import RewindActionTypes from './constants';

interface Rewind {
  current: number;
  isPlaying: boolean;
  sleepTime: number;
}

const initialState: Rewind = {
  current: 1,
  isPlaying: false,
  sleepTime: 1000,
};

const rewindReducer = (state = initialState, action: any): Rewind => {
  switch (action.type) {
    case RewindActionTypes.SET_CURRENT:
      if (action.operation === 'inc') {
        return {
          ...state,
          current: state.current + action.payload, // Increment current by action.payload (1)
        };
      } else if (action.operation === 'dec') {
        return {
          ...state,
          current: state.current - action.payload, // Decrement current by action.payload (1)
        };
      }
      return {
        ...state,
        current: action.payload,
      };
    case RewindActionTypes.SET_IS_PLAYING:
      return {
        ...state,
        isPlaying: action.payload,
      };
    case RewindActionTypes.SET_SLEEP_TIME:
      return {
        ...state,
        sleepTime: action.payload,
      };
    default:
      return state;
  }
};

export default rewindReducer;
