import React, { useState } from "react";
import {  useSelector } from "react-redux";
import { DeadlineSubmission } from "../../../../../store/ReadAssignment/model";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Reopen from "./Reopen";
import ExceptDraft from "./ExceptDraft";

interface ReopenExceptionProps {
  open: boolean;
  handleClose: () => void;
}


const ReopenException: React.FC<ReopenExceptionProps> = ({
  open,
  handleClose,
}) => {

  

  const deadlineSubmissions: DeadlineSubmission[] = useSelector(
    (state: any) => state.assignment.submissionData.deadlineSubmissions
  );

  const submissionId: string = useSelector(
    (state: any) => state.assignment.submissionData.submissionId
  );

  const status: string = useSelector(
    (state: any) => state.assignment.submissionData.status
  );
  const [mode, setMode] = useState<string>("SUBMISSION");

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: "60%",
          margin: 0,
          padding: 0,
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Button onClick={handleClose} startIcon={<CloseIcon />}></Button>
        Reopen/Exception
      </DialogTitle>
      <DialogContent dividers>
        <FormControl>
          <FormLabel id="exception-mode-name">Exception Mode</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="exception-mode-name"
            value={mode}
            onChange={handleChangeOption}
            row
          >
            <FormControlLabel
              value="SUBMISSION"
              control={<Radio />}
              label="Late Submission"
            />
            <FormControlLabel value="DRAFT" control={<Radio />} label="Draft Resubmission" />
          </RadioGroup>
        </FormControl>
        <Paper sx={{p:2}}>
          {mode === "SUBMISSION" ? <Reopen status={status} submissionId={submissionId}/> : <ExceptDraft submissions={deadlineSubmissions} submissionId={submissionId}/>}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default ReopenException;
