import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import Main from "../../elements/public/main";
import { Link } from "react-router-dom";
import { validate } from "../../actions/auth";
import { connect } from "react-redux";
import { Class } from "../../services/models/classes.model";
import { getClasses } from "../../services/classes.service";

class ClassIndex extends React.Component<any, any> {
  // TODO add interface
  constructor(props: any) {
    super(props);
    this.formatDate = this.formatDate.bind(this);

    this.state = {
      classList: null,
      dateformat: "yyyy-MM-dd",
    };
  }
  async componentDidMount() {
    this.props.dispatch(validate());

    try {
      const classes = await getClasses();
      this.setState({
        classList: classes.data,
      });
    } catch (err) {}
  }

  formatDate(date: string) {
    return date.substring(0, 10);
  }

  render() {
    const { classList } = this.state;

    return (
      <Main>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9} lg={10} my={1}>
            <Typography variant="h3">Instructor Class List</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} my={1}>
            <Button variant="contained" href="/RegisterClass" fullWidth>
              New Class
            </Button>
          </Grid>
        </Grid>

        {classList && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Class Name</TableCell>
                  <TableCell align="left">Enrollment Key</TableCell>
                  <TableCell align="left">Subject Area</TableCell>
                  <TableCell align="left">Student Level</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">End Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classList.map((row: Class) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Link to={"/classdetail/" + row.id}>{row.className}</Link>
                    </TableCell>
                    <TableCell align="left">{row.enrollkey}</TableCell>
                    <TableCell align="left">{row.subjectArea}</TableCell>
                    <TableCell align="left">{row.studentLevel}</TableCell>
                    <TableCell align="left">
                      {this.formatDate(row.startDate)}
                    </TableCell>
                    <TableCell align="left">
                      {this.formatDate(row.endDate)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Main>
    );
  }
}

export default connect()(ClassIndex);
