import React, { useState } from "react";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  TextareaAutosize,
  useTheme,
} from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createAssignmentAction,
  updateAssignmentAction,
} from "../../../../store/ClassDetails/actions";
import Tooltip from "@mui/material/Tooltip";
import { setSnackbarMessage } from "../../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../../store/UIActions/Snackbar/model";

import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import DraftSubmission from "./DraftSubmission/DraftSubmission";
import DraftSubmissionTable from "./DraftSubmission/DraftSubmissionTable";
import CloseIcon from "@mui/icons-material/Close";
import UnsavedNotification from "./UnsavedNotificatio";

dayjs.extend(utc);
dayjs.extend(timezone);

function RegisterAssignment(props: any) {
  const startDate: Dayjs = dayjs().tz(props.classTimeZone).startOf("day");
  const [draftStatus, setDraftStatus] = useState<boolean>(false);

  const minLastDate: Dayjs = dayjs().add(30, "minute").tz(props.classTimeZone);
  const defaultFormData = {
    id: "",
    title: "",
    pointValue: "",
    description: "",
    startDate: startDate, // Convert to JavaScript Date
    minStartdate: startDate, // Convert to JavaScript Date
    endDate: minLastDate, // Convert to JavaScript Date
    minEnddate: minLastDate, // Convert to JavaScript Date
    postDate: minLastDate, // Convert to JavaScript Date
    minPostdate: minLastDate, // Convert to JavaScript Date
    preventAfterDueDate: false,
    autoSubmission: false,
    autoEvaluate: false,
    autoAccept: false,
    autoItemsDisable: false,
    showAnalytics: false,
    classEndDate: null,

    draftSubmissions: [],
    multiDraft: false,
    changed: false,
  };

  const [defaultDraftSubmission, setDraftSubmission] = useState<
    any | undefined
  >(undefined);
  const getEditableDraft = (index: number) => {
    setDraftSubmission({
      ...formData.draftSubmissions[index],
      draftDeadline: dayjs(formData.draftSubmissions[index].draftDeadline),
      index,
    });
  };

  const theme = useTheme();
  // TODO add interface
  const [formData, setFormData] = useState(
    props.selectedEditAssignment // Check if props.selectedEditAssignment is not null
      ? {
          ...defaultFormData, // Spread the defaultFormData
          ...props.selectedEditAssignment, // Override with props.selectedEditAssignment values
          startDate: dayjs(props.selectedEditAssignment.startDate).tz(
            props.classTimeZone
          ),
          endDate: dayjs(props.selectedEditAssignment.endDate).tz(
            props.classTimeZone
          ),
          postDate: dayjs(props.selectedEditAssignment.postDate).tz(
            props.classTimeZone
          ),

          multiDraft: props.selectedEditAssignment.draftSubmissions?.length > 1,
        }
      : defaultFormData // Use defaultFormData if props.selectedEditAssignment is null
  );

  const handleAssignmentRegister = async (e: any) => {
    e.preventDefault();
    const {
      title,
      pointValue,
      startDate,
      endDate,
      postDate,
      description,
      preventAfterDueDate,
      autoSubmission,
      autoEvaluate,
      autoAccept,
      id,
      showAnalytics,
      draftSubmissions,
    } = formData;
    if (!draftStatus) {
      if (props.isEdit) {
        await props.dispatch(
          updateAssignmentAction({
            id: id,
            title,
            pointValue,
            startDate,
            endDate,
            postDate,
            preventAfterDueDate,
            autoSubmission,
            autoEvaluate,
            description,
            showAnalytics,
            autoAccept,
            draftSubmissions,
          })
        );
      } else {
        await props.dispatch(
          createAssignmentAction({
            id: props.classId,
            title,
            pointValue,
            startDate,
            endDate,
            postDate,
            preventAfterDueDate,
            autoSubmission,
            autoEvaluate,
            autoAccept,
            showAnalytics,
            description,
            draftSubmissions,
          })
        );
      }
      props.closeAssignmentModal();
      props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.SUCCESS,
          showSnackBar: true,
          message: `Successfully ${
            props.isEdit ? "updated" : "created"
          } assignment ${title}!`,
        })
      );
    } else {
      props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.WARNING,
          showSnackBar: true,
          message: `There is an unsaved draft submission. Please hit '+' before ${
            props.isEdit ? "Update" : "Create"
          }. `,
        })
      );
    }
  };
  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      changed: true,
    });
  };
  const handleChangeCheck = (e: any) => {
    if (e.target.name === "autoSubmission") {
      setFormData({
        ...formData,
        autoSubmission: e.target.checked,
        autoEvaluate: e.target.checked,
        autoItemsDisable: e.target.checked,
        preventAfterDueDate: e.target.checked,
        changed: true,
      });
    } else if (e.target.name === "multiDraft" && !e.target.checked) {
      if (formData.draftSubmissions.filter((c:any)=>c.draftStatus!=="DELETE")?.length<=1){
        setFormData({
          ...formData,
          [e.target.name]: e.target.checked,
          changed: true,
        });
      }
      else{
          props.dispatch(
            setSnackbarMessage({
              type: AlertTypes.ERROR,
              showSnackBar: true,
              message: `As you have a created a draft submission, you cannot unselect draft submissions.`,
            })
          );
          return false;
      }

    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
        changed: true,
      });
    }
  };
  // TODO add interface
  const handleDateChange = (e: any) => {
    var endDateTemp: Dayjs = dayjs(e).add(30, "minute");

    if (formData.endDate > endDateTemp) {
      endDateTemp = formData.endDate;
    }
    var postDateTemp = dayjs(e).add(2, "day");
    if (formData.postDate > postDateTemp) {
      postDateTemp = formData.postDate;
    }

    setFormData({
      ...formData,
      startDate: e,
      endDate: endDateTemp,
      postDate: postDateTemp,
      minEnddate: endDateTemp,
      minPostdate: dayjs(e).add(2, "day"),
      changed: true,
    });
  };
  // TODO add interface
  const handleEndDateChange = (e: any) => {
    var postDateTemp: Dayjs = dayjs(e).add(1, "day");
    if (formData.postDate > postDateTemp) {
      postDateTemp = formData.postDate;
    }

    setFormData({
      ...formData,
      endDate: e,
      postDate: postDateTemp,
      minPostdate: dayjs(e).add(1, "day"),
      changed: true,
    });
  };

  const validTotalScore = (newScore: number, index: number = -1): boolean => {
    var scores: number = parseFloat(newScore.toString());
    formData.draftSubmissions
      .filter((c: any) => c.draftType !== "FINAL")
      .forEach((element: any, rowIndex: number) => {
        const value =
          index !== rowIndex ? parseFloat(element.draftPointValue) : 0;
        scores = scores + value;
      });

    return scores <= 100 ? true : false;
  };

  const addNewDraft = (
    draftTitle: string,
    draftDeadline: Dayjs,
    draftPointValue: number
  ): boolean => {
    if (draftTitle.trim() === "" || !draftDeadline.isValid()) {
      props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `Fill the neccessory fields `,
        })
      );
      return false;
    }

    if (!validTotalScore(draftPointValue)) {
      props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `Total contribution of draft submissions is greater than 100%`,
        })
      );
      return false;
    }

    const newDraft = {
      draftId: null,
      draftTitle,
      draftDeadline,
      draftPointValue,
      draftStatus: "CREATE",
      draftType: "DRAFT",
    };

    // Add the new draft to a copy of the existing draftSubmissions array
    const updatedDraftSubmissions = [...formData.draftSubmissions, newDraft];

    // Sort the updated array by draftDeadline
    updatedDraftSubmissions.sort((a, b) =>
      dayjs(a.draftDeadline).diff(dayjs(b.draftDeadline))
    );

    // Set the sorted array in the state
    setFormData({
      ...formData,
      draftSubmissions: updatedDraftSubmissions,
    });
    setDraftSubmission(undefined);
    return true;
  };

  const updateDraftByIndex = (
    draftTitle: string,
    draftDeadline: Dayjs,
    draftPointValue: number,
    index: number
  ): boolean => {
    if (draftTitle.trim() === "" || !draftDeadline.isValid()) {
      props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `Fill the neccessory fields `,
        })
      );
      return false;
    }
    if (!validTotalScore(draftPointValue, index)) {
      props.dispatch(
        setSnackbarMessage({
          type: AlertTypes.ERROR,
          showSnackBar: true,
          message: `Total contribution of draft submissions is greater than 100%`,
        })
      );
      return false;
    }
    const updatedDraftSubmissions = [...formData.draftSubmissions];
    const updatedDraft = {
      ...updatedDraftSubmissions[index],
      draftTitle,
      draftDeadline,
      draftPointValue,
      draftStatus:
        updatedDraftSubmissions[index].draftId === null ? "CREATE" : "UPDATE",
    };
    updatedDraftSubmissions[index] = updatedDraft;

    updatedDraftSubmissions.sort((a, b) =>
      dayjs(a.draftDeadline).diff(dayjs(b.draftDeadline))
    );
    setFormData({
      ...formData,
      draftSubmissions: updatedDraftSubmissions,
    });
    setDraftSubmission(undefined);
    return true;
  };

  const removeDraftByIndex = (index: number) => {
    const updatedDraftSubmissions = [...formData.draftSubmissions];

    // Check if the item at the specified index should be removed or its status changed
    if (index >= 0 && index < updatedDraftSubmissions.length) {
      const draftToRemove = updatedDraftSubmissions[index];

      if (draftToRemove.draftId === null) {
        // Remove the item if draftId is null
        updatedDraftSubmissions.splice(index, 1);
      } else if (draftToRemove.draftStatus === "DELETE") {
        // Change the draftStatus to "DELETE"
        updatedDraftSubmissions[index] = {
          ...draftToRemove,
          draftStatus: "UPDATE",
        };
      } else {
        updatedDraftSubmissions[index] = {
          ...draftToRemove,
          draftStatus: "DELETE",
        };
      }

      // Update the state with the modified copy
      setFormData({
        ...formData,
        draftSubmissions: updatedDraftSubmissions,
      });
    }
  };

  const handleClose = () => {
    if (!formData.changed) props.closeAssignmentModal();
    else setChangeModel(true);
  };

  const [changeModel, setChangeModel] = useState<boolean>(false);

  return (
    <>
      <form onSubmit={handleAssignmentRegister}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {props.isEdit ? "Edit Assignment" : "Add Assignment"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            spacing={3}
            // component="form"
            // onSubmit={handleAssignmentRegister}
          >
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="title"
                name="title"
                label="Title"
                fullWidth
                variant="standard"
                margin="normal"
                onChange={handleChange}
                value={formData.title}
                sx={{ my: { xs: 1, md: 1 } }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="pointValue"
                name="pointValue"
                label="Point Value"
                fullWidth
                variant="standard"
                margin="normal"
                onChange={handleChange}
                type="number"
                value={formData.pointValue}
                sx={{ my: { xs: 1, md: 1 } }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={`Start Date : ${props.classTimeZone}`}
                  value={formData.startDate}
                  minDateTime={formData.minStartdate}
                  maxDate={formData.classEndDate}
                  onChange={(newValue) => {
                    handleDateChange(newValue);
                  }}
                  sx={{ width: "100%" }}
                  timezone={props.classTimeZone}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={`Deadline : ${props.classTimeZone}`}
                  value={formData.endDate}
                  minDateTime={formData.minEnddate}
                  maxDate={formData.classEndDate}
                  onChange={(newValue) => {
                    handleEndDateChange(newValue);
                  }}
                  sx={{ width: "100%" }}
                  timezone={props.classTimeZone}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltip
                describeChild
                title="If Autosubmission is selected, the system will submit whatever the student has written by the deadline and treat it as the complete assignment."
              >
                <FormControlLabel
                  // sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      name="autoSubmission"
                      id="autoSubmission"
                      onChange={handleChangeCheck}
                      checked={formData.autoSubmission}
                    />
                  }
                  label="Autosubmission at deadline?"
                  // labelPlacement="start"
                />
              </Tooltip>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
            <FormControlLabel
              // sx={{ ml: 0 }}
              control={
                <Checkbox
                  color="primary"
                  name="preventAfterDueDate"
                  id="preventAfterDueDate"
                  onChange={handleChangeCheck}
                  checked={formData.preventAfterDueDate}
                  disabled={formData.autoItemsDisable}
                />
              }
              label="Prevent student from writing more at deadline? "
              // labelPlacement="start"
            />
          </Grid> */}
            {/* <Grid item xs={12} sm={12}>
              <Tooltip
                describeChild
                title="If Autoevaluate is selected, the system will provide analytic scores to whatever that student has written by the deadline."
              >
                <FormControlLabel
                  // sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      name="autoEvaluate"
                      id="autoEvaluate"
                      onChange={handleChangeCheck}
                      checked={formData.autoEvaluate}
                      disabled={formData.autoItemsDisable}
                    />
                  }
                  label="Autoevaluate assignment at deadline?"
                  // labelPlacement="start"
                />
              </Tooltip>
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <Tooltip
                describeChild
                title="If autoaccept is selected, the system will accept all changes and remove all comments when the student submits the draft."
              >
                <FormControlLabel
                  // sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      name="autoAccept"
                      id="autoAccept"
                      onChange={handleChangeCheck}
                      checked={formData.autoAccept}
                    />
                  }
                  label="Autoaccept all changes upon student submission?"
                  // labelPlacement="start"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Tooltip
                describeChild
                title="If this is selected, the student will be able to view the analytics graphs after the assignment has been graded."
              >
                <FormControlLabel
                  // sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      name="showAnalytics"
                      id="showAnalytics"
                      onChange={handleChangeCheck}
                      checked={formData.showAnalytics}
                    />
                  }
                  label="Show analytics graphs to students after grading?"
                  // labelPlacement="start"
                />
              </Tooltip>
            </Grid>
            {/* multiDraft */}
            <Grid item xs={12} sm={12}>
              <Tooltip
                describeChild
                title="If this is selected, you will be able to create draft submissions for the assignment."
              >
                <FormControlLabel
                  // sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      color="primary"
                      name="multiDraft"
                      id="multiDraft"
                      onChange={handleChangeCheck}
                      checked={formData.multiDraft}
                    />
                  }
                  label="Draft submissions?"
                  // labelPlacement="start"
                />
              </Tooltip>
            </Grid>
            {formData.multiDraft && (
              <Grid item xs={12} sm={12}>
                <DraftSubmission
                  addNewDraft={addNewDraft}
                  timezone={props.classTimeZone}
                  minDate={formData.startDate}
                  maxDate={formData.endDate}
                  draftData={defaultDraftSubmission}
                  updateDraft={updateDraftByIndex}
                  draftStatus={setDraftStatus}
                />

                <DraftSubmissionTable
                  draftSubmissions={formData.draftSubmissions}
                  timezone={props.classTimeZone}
                  removeByIndex={removeDraftByIndex}
                  loadDraftByIndex={getEditableDraft}
                  finalDeadline={formData.endDate}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <TextareaAutosize
                minRows={7}
                aria-label="maximum height"
                name="description"
                placeholder={"Description"}
                onChange={handleChange}
                defaultValue={formData.description}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <span>{props.isEdit ? "Update" : "Create"}</span>
            </Button>
          </Grid> */}
          </Grid>
        </DialogContent>
        {/* todo: bring submit to  DialogActions*/}
        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Grid item xs={12} sm={12}>
            <Button type="submit" fullWidth variant="contained">
              <span>{props.isEdit ? "Update" : "Create"}</span>
            </Button>
          </Grid>
        </DialogActions>
      </form>
      <UnsavedNotification
        open={changeModel}
        handleClose={setChangeModel}
        handleDismiss={props.closeAssignmentModal}
      />
    </>
  );
}
export default RegisterAssignment;
