import Axios, { AxiosRequestConfig } from "axios";
import authHeader from "./auth-header";
import axios from "axios";
import { FeedBack } from "./models/assignments.model";

const URL = process.env.REACT_APP_API_URL;
const HOST = process.env.REACT_APP_API_URL;
const CLASSES = "api/classes";
const SUBMISSION = "api/submission"
const READ_SUBMISSION = "readsubmission";
const FEEDBACK = "feedback"

export function getClassDetailsIns(id: string) {
  const API_URL = URL + "/api/classes/getclass";
  return Axios.post(
    API_URL,
    {},
    {
      params: { id },
      headers: authHeader(),
    }
  );
}

export function addNewAssignment(
  id: string,
  title: string,
  pointValue: string,
  startDate: string,
  endDate: string,
  postDate: string,
  description: string
) {
  const API_URL = URL + "/api/classes/createAssignment";
  return Axios.post(
    API_URL,
    {
      id,
      title,
      pointValue,
      startDate,
      endDate,
      postDate,
      description,
    },
    {
      headers: authHeader(),
    }
  );
}

export function getSubmissionTextLogs(id: string) {
  const API_URL = URL + "/api/classes/progressLog";
  return Axios.post(
    API_URL,
    {},
    {
      params: { id },
      headers: authHeader(),
    }
  );
}

export function getSubmissionData(id: string) {
  const API_URL = URL + "/api/classes/readsubmission";
  return Axios.post(
    API_URL,
    {},
    {
      params: { submissionId: id },
      headers: authHeader(),
    }
  );
}

export async function getSubmissionDataAsync(submissionId: string) {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${READ_SUBMISSION}`,
    params: { submissionId },
    data: {},
  };
  try {
    const apiCall = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}

export async function addNewFeedback(submissionId: string, comment:string) {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "PATCH",
    url: `${HOST}/${SUBMISSION}/${FEEDBACK}`,
    params: {  },
    data: {submissionId,comment},
  };
  try {
    const apiCall = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}

export async function updateFeedbacks(submissionId: string, feedbacks: FeedBack[]) {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${SUBMISSION}/${FEEDBACK}`,
    params: {  },
    data: {submissionId,feedbacks},
  };
  try {
    const apiCall = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}
