import { Alert, Button, Typography } from "@mui/material";
import React from "react";

import * as Messages from "./Messages";
import { useDispatch } from "react-redux";
import { setSnackbarMessage } from "../../../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../../../store/UIActions/Snackbar/model";
import { reopenSubmissionAction } from "../../../../../store/ReadAssignment/actions";

interface reopenProps {
  status: string;
  submissionId:string;
}
const Reopen: React.FC<reopenProps> = ({ status,submissionId }) => {
  const isGrant = (): boolean => status !== "EXCEPTION";
  
  const dispatch = useDispatch();

  const setSubmissionException = async () => {
    const msg = `Assignment ${isGrant()?"reopened":" exception revoked"}!`
    // @ts-ignore
    await dispatch(reopenSubmissionAction(submissionId, isGrant()));
    dispatch(
      setSnackbarMessage({
        type: AlertTypes.SUCCESS,
        showSnackBar: true,
        message: msg,
      })
    );
  };

  return (
    <div>
      <Typography variant="subtitle2">
        <Alert severity="warning">
          {isGrant() ? Messages.GRANT : Messages.REVOKE}
        </Alert>
      </Typography>

      <Button fullWidth onClick={setSubmissionException}>
        {isGrant() ? "Grant" : "Revoke"}
      </Button>
    </div>
  );
};

export default Reopen;
