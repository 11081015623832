import React from "react";
import Main from "../../elements/public/main";
import { withRouter } from "../../common/withRouter";
import { utcToZonedTime } from "date-fns-tz";
import { parseISO, format, isAfter } from "date-fns";

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { validate } from "../../actions/auth";
import { connect } from "react-redux";
import { getStudentClass } from "../../services/classes.service";

class StudentClassPanel extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.showDesc = this.showDesc.bind(this);
    this.toTimeZone = this.toTimeZone.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.goToDetails = this.goToDetails.bind(this);
    this.isAssignmentAfterDueDate = this.isAssignmentAfterDueDate.bind(this);

    this.state = {
      id: this.props.router.params.classid,
      loading: false,
      myclass: null,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      modalBody: "",
      modalHeader: "",
      openModal: false,
    };
  }
  isAssignmentAfterDueDate(endDate: string) {
    return isAfter( new Date(),new Date(endDate));
  }
  async componentDidMount() {
    this.props.dispatch(validate());
    const { id } = this.state;
    try {
      const getMyClass = await getStudentClass(id);
      this.setState({
        myclass: getMyClass.data,
      });
    } catch (err) {
      alert("B");
    }
  }

  showDesc(e: any) {
    const assignment = this.state.myclass.assignments.find(
      (item: any) => item.id === e // TODO add interface
    );
    this.setState({
      modalBody: assignment.description,
      modalHeader: assignment.title,
      openModal: true,
    });
  }

  goToDetails(e: string) {
    window.location.replace("/assignment/" + e);
  }

  toTimeZone(date: string) {
    const { timeZone } = this.state;
    return format(
      utcToZonedTime(parseISO(date), timeZone),
      "yyyy-MM-dd kk:mm a"
    );
  }

  handleModalClose() {
    this.setState({
      modalBody: "",
      modalHeader: "",
      openModal: false,
    });
  }

  render() {
    const { myclass } = this.state;
    return (
      <Main>
        {myclass && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} sx={{ mt: 1 }}>
              <Paper>
                <Grid container spacing={2} m={0}>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Class name
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {myclass.className}
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Instructor
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {myclass.instructorName}
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Subject Area
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {myclass.subjectArea}
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Student Level
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {myclass.studentLevel}
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    Start Date
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {myclass.startDate.substring(0, 10)}
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ bgcolor: "text.hint" }}>
                    End Date
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {myclass.endDate.substring(0, 10)}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} m={0}>
                <Grid item xs={12}>
                  <Typography variant="h4" mb={1}>
                    {/* {" "} */}
                    Assignments List
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Title</TableCell>

                          <TableCell align="left">Start Date</TableCell>
                          <TableCell align="left">Due Date</TableCell>
                          <TableCell>Grade</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* TODO add interface */}
                        {myclass.assignments.map((row: any) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left">{row.title}</TableCell>

                            <TableCell align="left">
                              {this.toTimeZone(row.startDate)}
                            </TableCell>
                            <TableCell align="left">
                              {this.toTimeZone(row.endDate)}
                            </TableCell>
                            <TableCell align="left">
                              {row.score !== -1 ? row.score : "-"}
                            </TableCell>
                            <TableCell align="right">
                              {/* <IconButton
                                onClick={(e) => this.showDesc(row.id)}
                                aria-label="expand row"
                                size="small"
                              >
                                <InfoOutlinedIcon />
                              </IconButton> */}

                              <ButtonGroup variant="contained">
                                <Button onClick={(e) => this.showDesc(row.id)}>
                                  Description
                                </Button>
                                <Button
                                  onClick={(e) => this.goToDetails(row.id)}
                                >
                                  {this.isAssignmentAfterDueDate(row.endDate)
                                    ? "View"
                                    : "Write"}
                                </Button>
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Divider sx={{ my: 2 }} />
        <Dialog
          onClose={this.handleModalClose}
          open={this.state.openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          fullWidth
        >
          <DialogTitle>
            {this.state.modalHeader} <Divider />{" "}
          </DialogTitle>
          <Typography variant="body2" mx={4} mb={2}>
            {this.state.modalBody}
          </Typography>
        </Dialog>
      </Main>
    );
  }
}

export default withRouter(connect()(StudentClassPanel));
