import {
  createAssignment,
  deleteAssignment,
  getClassDetails,
  removeStudent,
  stopAssignment,
  updateAssignment,
  updateClassDetails,
  visibleAssignment,
} from "../../services/classDetails.service";
import { setIsLoaded, setIsLoading } from "../UIActions/Loading/actions";
import { apiIds } from "./constants";
import {
  ClassDetails,
  ClassStudentData,
  CreateAssignmentBody,
  UpdateClassDetails,
} from "./model";

export const GET_CLASS_DETAILS_SUCCESS = "GET_CLASS_DETAILS_SUCCESS";
export const DELETE_ASSIGNMENT_SUCCESS = "DELETE_ASSIGNMENT_SUCCESS";
export const CREATE_ASSIGNMENT_SUCCESS = "CREATE_ASSIGNMENT_SUCCESS";
export const UPDATE_ASSIGNMENT_SUCCESS = "UPDATE_ASSIGNMENT_SUCCESS";

export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS =
  "UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS";
export const UPDATE_ASSIGNMENT_STOP_SUCCESS = "UPDATE_ASSIGNMENT_STOP_SUCCESS";

export type ActionType =
  | CreateAssignmentData
  | UpdateAssignmentData
  | GetClassDetailsData
  | DeleteAssignmentData
  | DeleteStudentData
  | UpdateAssignmentVisibility
  | UpdateAssignmentStop;

//#region delete student start
interface DeleteStudentData {
  type: typeof DELETE_STUDENT_SUCCESS;
  payload: {
    studentId: string;
  };
}

export const deleteStudentSuccess = (studentId: string): DeleteStudentData => ({
  type: DELETE_STUDENT_SUCCESS,
  payload: {
    studentId,
  },
});

export const deleteStudentAction =
  ({ classId, studentId }: ClassStudentData) =>
  async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.deleteStudent));
    try {
      const deleteStudentApi = await removeStudent(classId, studentId);
      if (deleteStudentApi.data === true) {
        dispatch(deleteStudentSuccess(studentId));
      }
      dispatch(setIsLoaded(apiIds.deleteStudent));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.deleteStudent));
      throw err;
    }
  };

//#endregion delete student end

//#region get class details start
interface GetClassDetailsData {
  type: typeof GET_CLASS_DETAILS_SUCCESS;
  payload: {
    classDetails: ClassDetails;
  };
}

export const getClassDetailsSuccess = (
  classDetails: ClassDetails
): GetClassDetailsData => ({
  type: GET_CLASS_DETAILS_SUCCESS,
  payload: {
    classDetails,
  },
});

export const getClassDetailsAction =
  (classId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.getClassDetails));
    try {
      const classDetails = await getClassDetails(classId);
      dispatch(getClassDetailsSuccess(classDetails.data));
      dispatch(setIsLoaded(apiIds.getClassDetails));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.getClassDetails));
      throw err;
    }
  };

export const updateClassDetailsAction =
  (updateDetails: UpdateClassDetails) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.updateClassDetails));
    try {
      const classDetails = await updateClassDetails(updateDetails);
      dispatch(getClassDetailsSuccess(classDetails.data));
      dispatch(setIsLoaded(apiIds.updateClassDetails));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.updateClassDetails));
      throw err;
    }
  };

//#endregion get class details end

//#region delete assignment start
interface DeleteAssignmentData {
  type: typeof DELETE_ASSIGNMENT_SUCCESS;
  payload: ClassDetails;
}

export const deleteAssignmentSuccess = (
  assignmentBody: ClassDetails
): DeleteAssignmentData => ({
  type: DELETE_ASSIGNMENT_SUCCESS,
  payload: {
    ...assignmentBody,
  },
});

export const deleteAssignmentAction =
  (assignmentId: string) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.deleteAssignment));
    try {
      const deleteAssignmentApi = await deleteAssignment(assignmentId);
      dispatch(deleteAssignmentSuccess(deleteAssignmentApi.data));
      dispatch(setIsLoaded(apiIds.deleteAssignment));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.deleteAssignment));
      throw err;
    }
  };

//#endregion delete assignment end

//#region create new assignment start

interface CreateAssignmentData {
  type: typeof CREATE_ASSIGNMENT_SUCCESS;
  payload: ClassDetails;
}
export const createAssignmentSuccess = (
  assignmentBody: ClassDetails
): CreateAssignmentData => ({
  type: CREATE_ASSIGNMENT_SUCCESS,
  payload: {
    ...assignmentBody,
  },
});
export const createAssignmentAction =
  (assignmentBody: CreateAssignmentBody) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.createAssignment));
    try {
      console.log(assignmentBody);
      const createAssignmentApi = await createAssignment(assignmentBody);
      dispatch(createAssignmentSuccess(createAssignmentApi.data));
      dispatch(setIsLoaded(apiIds.createAssignment));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.createAssignment));

      throw err;
    }
  };
//#endregion create new assignment end

//#region update assignment start
interface UpdateAssignmentData {
  type: typeof UPDATE_ASSIGNMENT_SUCCESS;
  payload: ClassDetails;
}
export const updateAssignmentSuccess = (
  assignmentBody: ClassDetails
): UpdateAssignmentData => ({
  type: UPDATE_ASSIGNMENT_SUCCESS,
  payload: {
    ...assignmentBody,
  },
});
export const updateAssignmentAction =
  (assignmentBody: CreateAssignmentBody) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.updateAssignment));
    try {
      const createAssignmentApi = await updateAssignment(assignmentBody);
      dispatch(updateAssignmentSuccess(createAssignmentApi.data));
      dispatch(setIsLoaded(apiIds.updateAssignment));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.updateAssignment));

      throw err;
    }
  };
// update new assignment end
//#endregion
//#region update assignment visibility

interface UpdateAssignmentVisibility {
  type: typeof UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS;
  payload: ClassDetails;
}

export const updateAssignmentVisibilitySuccess = (
  assignmentBody: ClassDetails
): UpdateAssignmentVisibility => ({
  type: UPDATE_ASSIGNMENT_VISIBILITY_SUCCESS,
  payload: {
    ...assignmentBody,
  },
});

export const updateAssignmentVisibilityAction =
  (assignmentId: string, status: boolean) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.updateAssignmentVisibility));
    try {
      const updateVisibilityApi = await visibleAssignment(assignmentId, status);
      dispatch(deleteAssignmentSuccess(updateVisibilityApi.data));
      dispatch(setIsLoaded(apiIds.updateAssignmentVisibility));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.updateAssignmentVisibility));
      throw err;
    }
  };
//#end region
//#region update assignment Stop
interface UpdateAssignmentStop {
  type: typeof UPDATE_ASSIGNMENT_STOP_SUCCESS;
  payload: ClassDetails;
}

export const updateAssignmentStopSuccess = (
  assignmentBody: ClassDetails
): UpdateAssignmentStop => ({
  type: UPDATE_ASSIGNMENT_STOP_SUCCESS,
  payload: {
    ...assignmentBody,
  },
});

export const updateAssignmentStopAction =
  (assignmentId: string, status: boolean) => async (dispatch: any) => {
    dispatch(setIsLoading(apiIds.updateAssignmentStopStatus));
    try {
      const updateStopApi = await stopAssignment(assignmentId, status);
      dispatch(updateAssignmentStopSuccess(updateStopApi.data));
      dispatch(setIsLoaded(apiIds.updateAssignmentStopStatus));
    } catch (err) {
      dispatch(setIsLoaded(apiIds.updateAssignmentStopStatus));
      throw err;
    }
  };

//#end region
