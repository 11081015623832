import React, { useEffect, useState } from "react";
import Main from "../../elements/public/main";
import { useParams } from "react-router-dom";
import { confirmEmail } from "../../services/auth.service";
import { Typography } from "@mui/material";

const ConfirmEmail: React.FC = () => {
  const { id } = useParams();
  const [message, setMessage] = useState<string>("Wait for the confirmation");
  useEffect(() => {
    const Confirm = async () => {
      try {
        console.log(id);
        if (id !== undefined) {
          const response = await confirmEmail(id);
          if (response.status === 200) {
            setMessage(
              "Your account is now activated. You will be redirected to the login page."
            );
            setTimeout(()=>{
                window.location.href = "/login"
            },2000);
          }
        }
      } catch (e: any) {
        let errorMessage = "An error occurred";

        if (e.response && e.response.data && e.response.data.message) {
          errorMessage = e.response.data.message;
        } else if (e.message) {
          errorMessage = e.message;
        }
        setMessage(errorMessage);
      }
    };

    if(id!==undefined){
        Confirm();
    }
  }, [id]);

  return (
    <Main>
      <Typography>{message}</Typography>
    </Main>
  );
};

export default ConfirmEmail;
