import React, {  useState } from "react";
import {
  DialogTitle,
  Dialog,
  Button,
  DialogContent,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Alert,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import {
  Deadline,
  DeadlineSubmission,
} from "../../../../store/ReadAssignment/model";

import SendIcon from "@mui/icons-material/Send";
import { draftSubmitAction } from "../../../../store/ReadAssignment/actions";
// import { TrackChangeMethods } from "../constants";
// import {
//   setIsLoaded,
//   setIsLoading,
// } from "../../../../store/UIActions/Loading/actions";

interface SubmissionPanelProps {
  open: boolean;
  handleClose: () => void;
}

const SubmissionPanel: React.FC<SubmissionPanelProps> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const submissionId: string = useSelector(
    (state: any) => state.assignment.submissionData.submissionId
  );

  const deadlineSubmissions: DeadlineSubmission[] = useSelector(
    (state: any) => state.assignment.submissionData.deadlineSubmissions
  );

  // Replace to auto accept on instructor side
  // const autoAccept: boolean = useSelector(
  //   (state: any) => state.assignment.submissionData.autoAccept
  // );

  const deadlines: Deadline[] = useSelector(
    (state: any) => state.assignment.submissionData.deadlines
  );

  const [draftId, setDraftId] = useState<string>("");

  const handleChange = (event: SelectChangeEvent) => {
    setDraftId(event.target.value as string);
  };

  const isSubmitted = (): boolean => {
    return deadlineSubmissions.some((e) => e.draftId === draftId);
  };

  // Activate to have auto accept on instructor side
  // const TRAccept = async () => {
  //   const obj = {
  //     MessageId: "CallPythonScript",
  //     SendTime: Date.now(),
  //     ScriptFile: TrackChangeMethods.FILENAME,
  //     Function: TrackChangeMethods.FINALIZE,
  //     Values: {},
  //   };
  //   window.frames[0].postMessage(JSON.stringify(obj), "*");
  //   handleClose();
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, 5000);
  //   });
  // };

  const handleSubmit = async () => {
    if (draftId !== "" || draftId !== undefined) {
      // Activate to have auto accept on instructor side
      // if (autoAccept === true) {
      //   dispatch(setIsLoading(TrackChangeMethods.apiId));
      //   await TRAccept();
      //   dispatch(setIsLoaded(TrackChangeMethods.apiId));
      // }

      // @ts-ignore
      dispatch(draftSubmitAction(submissionId, draftId));
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: "60%",
          margin: 0,
          padding: 0,
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Button onClick={handleClose} startIcon={<CloseIcon />}></Button>
        Draft to Submit
      </DialogTitle>
      <DialogContent dividers>
        {deadlines.length > 0 && (
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="draft-select-label">Drafts</InputLabel>
              <Select
                labelId="draft-select-label"
                id="draft-select"
                value={draftId}
                label="Draft submission"
                onChange={handleChange}
                required
              >
                {deadlines.map((item) => (
                  <MenuItem value={item.draftId} key={item.draftId}>
                    {item.draftTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isSubmitted() && (
              <Alert severity="warning">
                A submission already exists for the selected draft.
              </Alert>
            )}
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SubmissionPanel;
