import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Deadline,
  DeadlineSubmission,
} from "../../../../store/ReadAssignment/model";
import { useSelector, useDispatch } from "react-redux";
import { tocustomTimeZoneSimple } from "../../../../services/date.service";
import { draftGradeAction } from "../../../../store/ReadAssignment/actions";

interface GradeBookProps {
  open: boolean;
  handleClose: () => void;
}

const GradeBook: React.FC<GradeBookProps> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const timezone: string = useSelector(
    (state: any) => state.assignment.submissionData.classTimezone
  );

  const deadlineSubmissions: DeadlineSubmission[] = useSelector(
    (state: any) => state.assignment.submissionData.deadlineSubmissions
  );

  const deadlines: Deadline[] = useSelector(
    (state: any) => state.assignment.submissionData.deadlines
  );

  // Using a separate state to store the sorted array
  const [sortedSubmissions, setSortedSubmissions] = useState<
    DeadlineSubmission[]
  >([]);

  const getTotalScore = () => {
    var total = 0;
    deadlines.forEach((dl) => {
      // Filter submissions that match the condition
      const matchingSubmissions = sortedSubmissions.filter(
        (sb) => sb.draftId === dl.draftId && sb.grade !== -1
      );

      // Get the last element of the filtered array, if it exists
      total +=
        matchingSubmissions.length > 0
          ? (matchingSubmissions[matchingSubmissions.length - 1].grade *
              dl.draftPointValue) /
            100
          : 0;
    });

    return total;
  };

  const getWeight = (draftId: string, draftSubmissionId: string): number => {
    const matchingSubmissions = sortedSubmissions.filter(
      (sb) => sb.draftId === draftId
    );
    const elementindex = matchingSubmissions.findIndex(
      (c) => c.draftSubmissionId === draftSubmissionId
    );
    if (elementindex === matchingSubmissions.length - 1) {
      return deadlines.filter((c) => c.draftId === draftId)[0].draftPointValue;
    }
    return 0;
  };

  useEffect(() => {
    const sortedArray: DeadlineSubmission[] = [...deadlineSubmissions].sort(
      (a, b) => {
        if (a.draftId.localeCompare(b.draftId) === 0) {
          return a.draftSubmissionId.localeCompare(b.draftSubmissionId);
        } else {
          return a.draftId.localeCompare(b.draftId);
        }
      }
    );

    setSortedSubmissions(sortedArray);
  }, [deadlineSubmissions]);

  const handleUpdateDraftGrades = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const draftSubmissionId = event.target.getAttribute(
      "data-draft-submission-id"
    );
    const grade =
      event.target.value === "" ? -1 : parseFloat(event.target.value);
    const element = sortedSubmissions.find(
      (c) => c.draftSubmissionId === draftSubmissionId
    );

    if (element !== undefined) {
      if (element.grade !== grade) {
        console.log(`${element.grade}, ${grade}`);
        // @ts-ignore
        dispatch(draftGradeAction(draftSubmissionId, grade));
        console.log("updated");
      }
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: "unset",
          width: "60%",
          margin: 0,
          padding: 0,
        },
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Button onClick={handleClose} startIcon={<CloseIcon />}></Button>
        Grade Book
      </DialogTitle>
      <DialogContent dividers>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Submission Date</TableCell>
              <TableCell>Submission Status</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Grade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSubmissions.map((item, index) => (
              <TableRow key={`gb-${index}`}>
                <TableCell>{item.title}</TableCell>
                <TableCell>
                  {tocustomTimeZoneSimple(item.date.toString(), timezone)}
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{`${getWeight(
                  item.draftId,
                  item.draftSubmissionId
                )}%`}</TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    type="number"
                    inputProps={{
                      "data-draft-submission-id": item.draftSubmissionId,
                    }}
                    defaultValue={item.grade === -1 ? null : item.grade}
                    onBlur={handleUpdateDraftGrades}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>Total Grade</TableCell>
              <TableCell>
                <strong>{getTotalScore()}</strong>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default GradeBook;
