export const gradeStatus = {
  EvaluateFinalize: [
    "INITIAL",
    "REVISION",
    "EXCEPTION",
    "DRAFT_SUBMISSION",
    "INCOMPLETE",
  ],
  Reopen: [
    "FINAL_SUBMISSION",
    "AUTO_SUBMISSION",
    "INSTRUCTOR_SUBMISSION",
    "INCOMPLETE",
  ],
  Grade: ["FINAL_SUBMISSION", "AUTO_SUBMISSION", "INSTRUCTOR_SUBMISSION"],
};

export const tooltips = {
  EFFORT_TOOLTIP:
    "Effort is a measure of the amount of effort the student expended writing the assignment in terms of time, writing, and editing (including the types of edits). While the Effort score is meant to be advisory, the instructor may consider allocating up to 20 percent of the grade for the assignment to this metric.",
  REVISION_TOOLTIP:
    "Revision is a measure of how much deep revision the student engaged in for the assignment. While the Revision score is meant to be advisory, the instructor may consider allocating up to 20 percent of the grade for the assignment to this metric.",
};

export const loading:string[] = ["getSubmissionData","endCommenting","draftSubmit","addNewFeedback","gradeSubmission","evaluateSubmission","TrackChangeAccept"];

export const TrackChangeMethods={ACTIVATE:"Activate", DEACTIVATE:"DeActivate", SHOW:"Show", FILENAME : "tr.py", ACCEPTALL:"AcceptAll", FINALIZE:"Finalize", apiId:"TrackChangeAccept"}