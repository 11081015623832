export interface StudentAssignmentData {
  assignment: AssignmentDetail;
  submissionId: string;
}

export const initialState: StudentAssignmentData = {
  assignment: {} as AssignmentDetail,
  submissionId: "",
};

export interface CommentedFile{
  fileId:string;
  commentDate:Date;
}

export interface deadline {
  draftId: string;
  draftStatus: string;
  draftTitle: string;
  draftType: string;
  draftPointValue: number;
  draftDeadline: Date;
}

export interface timeline {
  draftId: string;
  title: string;
  date: Date;
  submitDate: Date;
  status: string;
  exception: boolean;
  fileId: string;
  commentFiles:CommentedFile[];
}

export interface Feedback{
  comment:string;
  timestamp:Date;
  read:boolean;
}

interface submission {
  id: string;
  createDate: Date;
  submitDate: Date;
  score: number;
  status: string;
  feedbacks: Feedback[];
}

export interface AssignmentDetail {
  assignmentId: string;
  courseId: string;
  title: string;
  pointValue: number;
  description: string;
  startDate:Date;
  endDate: Date;
  postDate: Date;
  preventAfterDueDate: boolean;
  autoSubmission: boolean;
  autoAccept:boolean;
  showAnalytics:boolean;
  deadlines: deadline[];
  timeline: timeline[];
  submissions: submission[];
}

export type TimelinePage = "step" | "activity";
