import React, { useEffect, useState } from "react";
import Main from "../../../elements/public/main";
import { withRouter } from "../../../common/withRouter";
import {
  Backdrop,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Grid,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { validate } from "../../../actions/auth";
import { connect, useDispatch } from "react-redux";
import StudentsList from "./Students/StudentsList";
import Details from "./Details/Details";
import RegisterAssignment from "./Assignments/RegisterAssignment";
import Assignments from "./Assignments/Assignments";
import {
  deleteAssignmentAction,
  getClassDetailsAction,
} from "../../../store/ClassDetails/actions";
import { apiIds } from "../../../store/ClassDetails/constants";
import { Assignment, AssignmentInit } from "../../../store/ClassDetails/model";
import { setSnackbarMessage } from "../../../store/UIActions/Snackbar/actions";
import { AlertTypes } from "../../../store/UIActions/Snackbar/model";
import AddIcon from "@mui/icons-material/Add";
import EditClass from "./Details/Edit";

function InstructorClassPanelTSX(props: any) {

  useEffect(() => {
    props.dispatch(validate());
    props.dispatch(getClassDetailsAction(props.router.params.classid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState({
    id: "",
    loading: false,
    value: 0,
    openModal: false,
    openDeleteModal: false,
    selectedDeleteAssignment: {
      ...AssignmentInit,
    },
    selectedEditAssignment: {},
    modalBody: "",
    modalHeader: "",
    openAssignmentModal: false,
    isEditAssignment: false,
    isEditClass: false,
  });
  const DETAILS = 0;
  const STUDENTS = 1;
  const ASSIGNMENTS = 2;

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const handleAssignmentModalOpen = (
    action: string,
    assignment: Assignment
  ) => {
    if (action === "create") {
      setState({
        ...state,
        openAssignmentModal: true,
        isEditAssignment: false,
      });
    }
    if (action === "edit") {
      setState({
        ...state,
        openAssignmentModal: true,
        isEditAssignment: true,
        selectedEditAssignment: assignment.autoSubmission
          ? {
              ...assignment,
              autoItemsDisable: true,
            }
          : assignment,
      });
    }
  };
  const handleAssignmentModalClose = () => {
    setState({
      ...state,
      openAssignmentModal: false,
      isEditAssignment: false,
      selectedEditAssignment: {},
    });
  };
  const showDesc = (e: any) => {
    const assignment = props.classDetails.assignments.find(
      (item: any) => item.id === e
    );
    setState({
      ...state,
      modalBody: assignment.description,
      modalHeader: assignment.title,
      openModal: true,
      openDeleteModal: false,
    });
  };
  const deleteAssignment = (assignment: Assignment) => {
    setState({
      ...state,
      openDeleteModal: true,
      selectedDeleteAssignment: assignment,
    });
  };
  const isLoading = () => {
    return props.loader.loadingIds.includes(apiIds.getClassDetails);
  };

  const handleOpenEditClass = () => {
    setState({
      ...state,
      isEditClass: true,
    });
  };
  const handleCloseEditClass = () => {
    setState({
      ...state,
      isEditClass: false,
    });
  };

  return (
    <Main>
      {props.classDetails && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={state.value}
              onChange={(event: any, newValue: any) => {
                if (!state.isEditClass) {
                  setState({
                    ...state,
                    value: newValue,
                  });
                } else {
                  props.dispatch(
                    setSnackbarMessage({
                      type: AlertTypes.WARNING,
                      showSnackBar: true,
                      message:
                        "You can not switch between tabs while you edit the class details",
                    })
                  );
                }
              }}
              aria-label="Class details tabs"
              centered
            >
              <Tab label="Details" {...a11yProps(DETAILS)} />
              <Tab label="Students" {...a11yProps(STUDENTS)} disabled={state.isEditClass}/>
              <Tab label="Assignments" {...a11yProps(ASSIGNMENTS)} disabled={state.isEditClass}/>
            </Tabs>
          </Box>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
              {state.value === DETAILS && (
                <>
                  {!state.isEditClass && (
                    <Details
                      className={props.classDetails.className}
                      subjectArea={props.classDetails.subjectArea}
                      studentLevel={props.classDetails.studentLevel}
                      startDate={props.classDetails.startDate}
                      endDate={props.classDetails.endDate}
                      classTimeZone={props.classDetails.classTimeZone}
                      handleOpenEdit={handleOpenEditClass}
                    />
                  )}
                  {state.isEditClass && (
                    <EditClass handleEditClose={handleCloseEditClass} dispatch={props.dispatch}/>
                  )}
                </>
              )}
              {state.value === STUDENTS && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <StudentsList
                      students={props.classDetails.students}
                      assignments={props.classDetails.assignments}
                    />
                  </Grid>
                </Grid>
              )}
              {state.value === ASSIGNMENTS && (
                <>
                  <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    onClick={() =>
                      handleAssignmentModalOpen("create", AssignmentInit)
                    }
                  >
                    Add assignment
                  </Button>
                  <br />
                  <br />
                  <br />
                  <Dialog
                    open={state.openAssignmentModal}
                    // todo: remove onclose and add a iconbutton for it
                    // onClose={handleAssignmentModalClose}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullWidth
                    maxWidth="md"
                    disableEscapeKeyDown={true}
                    disableEnforceFocus={true}
                    scroll="paper"
                  >
                    <RegisterAssignment
                      classId={props.router.params.classid}
                      dispatch={props.dispatch}
                      closeAssignmentModal={handleAssignmentModalClose}
                      isEdit={state.isEditAssignment}
                      selectedEditAssignment={state.selectedEditAssignment}
                      classTimeZone={props.classDetails.classTimeZone}
                    />
                  </Dialog>

                  <Assignments
                    assignments={props.classDetails.assignments}
                    students={props.classDetails.students}
                    showDesc={showDesc}
                    deleteAssignment={deleteAssignment}
                    editAssignment={(assignment: Assignment) => {
                      handleAssignmentModalOpen("edit", assignment);
                    }}
                    classTimeZone={props.classDetails.classTimeZone}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading()}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        onClose={() => {
          setState({
            ...state,
            modalBody: "",
            modalHeader: "",
            openModal: false,
          });
        }}
        disableEscapeKeyDown={true}
        open={state.openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>
          {state.modalHeader} <Divider />{" "}
        </DialogTitle>
        <Typography variant="body2" mx={4} mb={2}>
          {state.modalBody}
        </Typography>
      </Dialog>

      <Dialog
        onClose={() => {
          setState({
            ...state,
            openDeleteModal: false,
            // selectedDeleteAssignment: { title: "", id: "" },
          });
        }}
        disableEscapeKeyDown={true}
        open={state.openDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Delete assignment
          <Divider />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete assignment{" "}
              <b>{state.selectedDeleteAssignment?.title}</b> ?
            </DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setState({
                ...state,
                openDeleteModal: false,
                // selectedDeleteAssignment: { title: "", id: "" },
              });
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await props.dispatch(
                deleteAssignmentAction(state.selectedDeleteAssignment?.id)
              );
              setState({
                ...state,
                openDeleteModal: false,
              });
              props.dispatch(
                setSnackbarMessage({
                  type: AlertTypes.SUCCESS,
                  showSnackBar: true,
                  message: `Successfully deleted assignment ${state.selectedDeleteAssignment?.title}!`,
                })
              );
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Main>
  );
}
// TODO add interface
function mapStateToProps(state: any) {
  const { classDetails, loader } = state;
  return {
    classDetails,
    loader,
  };
}

export default withRouter(connect(mapStateToProps)(InstructorClassPanelTSX));
