import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "../../../services/auth-header";

import {
  ClassDetails,
  ClassListItem,
  SubmissionDetails,
  TimeMetricsDetails,
} from "./model";

const HOST = process.env.REACT_APP_API_URL;

const CLASS_LIST = "api/education/courses";
const CLASS_DETAIL = "api/education/course";
const SUBMISSION_DETAIL = "api/education/submission";
const NEXT_SUBMISSION = "api/education/nextsubmission";
const ESSAY_MAP = "api/education/getChangeDist";
const HEAT_MAP = "api/education/getHeatmapLogs";
const WORDCHANGE = "api/education/wordchangebyessayTime";

export const getClassesList = async () => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${HOST}/${CLASS_LIST}`,
    params: {},
    data: {},
  };
  try {
    const apiCall: AxiosResponse<ClassListItem[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getClassDetail = async (id: string) => {
  if (id === undefined) return;
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${HOST}/${CLASS_DETAIL}`,
    params: { id },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<ClassDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

const _downloadExcelFile = async (id: string) => {
  if (id === undefined) return;
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASS_DETAIL}`,
    params: { id },
    data: {},
    responseType: "blob",
  };
  try {
    const apiCall: AxiosResponse<Blob> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const download = async (id: string) => {
  if (id === undefined) return;
  const response = await _downloadExcelFile(id);
  try {
    if (response === undefined) return;
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "class_detail.xlsx"; // Set the desired file name
    document.body.appendChild(a);
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
  } catch (err) {
    throw err;
  }
};

export const getSubmissionDetails = async (id: string) => {
  if (id === undefined) return;
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "GET",
    url: `${HOST}/${SUBMISSION_DETAIL}`,
    params: { id },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<SubmissionDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getSubmissionTimeMetrics = async (id: string) => {
  if (id === undefined) return;
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${SUBMISSION_DETAIL}`,
    params: { id },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<TimeMetricsDetails> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getNextSubmissionId = async (
  classId: string,
  studentIndex: number,
  assignmentId: string,
  direction: number
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${NEXT_SUBMISSION}`,
    params: {},
    data: { classId, studentIndex, assignmentId, direction },
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getEssayMapData = async (id: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${ESSAY_MAP}`,
    params: {id},
    data: {  },
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getHeatMapData = async (id: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${HEAT_MAP}`,
    params: {id},
    data: {  },
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getWordChangeData = async (id: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${WORDCHANGE}`,
    params: {id},
    data: {  },
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};
