import React, { useState, useEffect, useRef } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Fab, Grid } from "@mui/material";
import * as dateService from "../../../../services/date.service";
import Container from "@mui/material/Container";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { showTextDiff } from "../../../../services/classes.service";
import ChangeLineGraph from "./ChangeGraph";
import { useSelector, useDispatch } from "react-redux";
import { SubmissionDetails } from "../../../../services/models/assignments.model";
import FastForwardIcon from "@mui/icons-material/FastForward";
import {
  setCurrent,
  incCurrent,
  decCurrent,
  setIsPlaying,
} from "../../../../store/Rewind/actions";
import RewindSlider from "./Slider";
import Legend from "../../../../components/Legend";

const WritingProgress: React.FC = () => {
  const dispatch = useDispatch();
  const submissionData: SubmissionDetails = useSelector(
    (state: any) => state.assignment.submissionData
  );
  const { current, isPlaying, sleepTime } = useSelector(
    (state: any) => state.rewind
  );
  // const [current, setCurrent] = useState<number>(1);
  // const [isPlaying, setIsPlaying] = useState<boolean>(false);
  // const [sleepTime] = useState<number>(250);
  const [content, setContent] = useState<string | null>(null);
  const [sectionId, setSectionId] = useState<number>(0);
  const [disableUp, setDisableUp] = useState<boolean>(false);
  const [disableDown, setDisableDown] = useState<boolean>(false);
  const [speed, setSpeed] = useState<number>(1);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [iterationStep, setIterationStep] = useState<number>(0);
  // let iterationStep = 0;

  useEffect(() => {
    scrollToSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  useEffect(() => {
    // if the current slider state reaches the end of logs array then stop playing
    if (current > submissionData.submissionLog.logs.length) {
      dispatch(setIsPlaying(false));
      setCurrent(submissionData.submissionLog.logs.length - 1);
    } else {
      const updateDocument = async () => {
        await loadDocument();
        setSectionId(0);
        scrollToSection();
      };
      if (current + iterationStep < submissionData.submissionLog.logs.length) {
        updateDocument();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    const isLogsLengthNotEqToCurrentOrLess =
      current !== submissionData.submissionLog.logs.length - 1 &&
      current < submissionData.submissionLog.logs.length - 1;

    // run the player only when the current state value is less than length of logs array
    if (isPlaying && isLogsLengthNotEqToCurrentOrLess) {
      const timeout = setTimeout(() => {
        handleNext();
      }, sleepTime);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, current]);

  const scrollToSection = () => {
    const section = document.getElementById(
      "section" + sectionId.toString()
    ) as HTMLElement;
    const nextsection = sectionId + 1;
    const nextElementExists = !!document.getElementById(
      "section" + nextsection.toString()
    );
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    setDisableUp(sectionId <= 0);
    setDisableDown(!nextElementExists);
  };

  const increaseSpeed = (multiplier: number) => {
    if (speed === 4) {
      setSpeed(1);
    } else {
      setSpeed(speed * multiplier);
    }
  };
  const handleNext = () => {
    dispatch(incCurrent(speed));
  };

  const handleDown = () => {
    setSectionId(sectionId + 1);
  };

  const handleUp = () => {
    setSectionId(sectionId - 1);
  };

  const handlePrev = () => {
    dispatch(decCurrent());
  };

  const handlePlay = (value: boolean) => {
    dispatch(setIsPlaying(value));
  };
  const handleReset = (value: boolean) => {
    dispatch(setCurrent(1));
    dispatch(setIsPlaying(value));
  };

  const loadDocument = async () => {
    if (speed === 2) {
      // increase the difference between old and new id by 2
      setIterationStep(1);
    } else if (speed === 4) {
      // increase the difference between old and new id by 4
      setIterationStep(3);
    }
    // else if (speed === 8) {
    //   iterationStep = 7;
    // }
    else {
      setIterationStep(0);
    }
    const oldId = submissionData.submissionLog.logs[current - 1].id;
    const newId = submissionData.submissionLog.logs[current + iterationStep].id;

    const showDiff = await showTextDiff(oldId, newId);
    await setContent(showDiff.data.response);
  };
  const isLogsLengthNotEqToCurrentOrLess =
    current !== submissionData.submissionLog.logs.length - 1 &&
    current < submissionData.submissionLog.logs.length - 1;

  return (
    <Container maxWidth={false}>
      {submissionData && (
        <Grid container sx={{ height: "100vh" }}>
          <Grid item xs={12} md={12} lg={8} xl={8}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <ChangeLineGraph />
            </Grid>
            <RewindSlider />

            <Container
              maxWidth={false}
              sx={{
                contain: "layout",
                height: "85vh",
                overflow: "auto",
                display: "flex",
                alignItems: "flex-start",
                minHeight: "85vh",
                border: "2px",
              }}
            >
              <div
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: content || "" }}
                ref={sectionRef}
              />
            </Container>
          </Grid>
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <Box
              sx={{
                "& > :not(style)": { m: 1 },
                position: "sticky",
                top: "4rem",
              }}
            >
              <Fab
                color="primary"
                aria-label="add"
                onClick={handlePrev}
                disabled={current <= 1}
              >
                <ChevronLeftIcon />
              </Fab>
              <Fab
                aria-label="like"
                color="secondary"
                onClick={() =>
                  current >= submissionData.submissionLog.logs.length - 1
                    ? handleReset(!isPlaying)
                    : handlePlay(!isPlaying)
                }
              >
                {current >= submissionData.submissionLog.logs.length - 1 ? (
                  <ReplayOutlinedIcon />
                ) : isPlaying ? (
                  <PauseOutlinedIcon />
                ) : (
                  <PlayArrowOutlinedIcon />
                )}
              </Fab>
              <Fab
                color="primary"
                variant="extended"
                onClick={() => increaseSpeed(2)}
              >
                <FastForwardIcon />
                {speed}
              </Fab>
              <Fab
                color="primary"
                aria-label="edit"
                onClick={() => dispatch(incCurrent(1))}
                disabled={
                  current >= submissionData.submissionLog.logs.length - 1
                }
              >
                <ChevronRightOutlinedIcon />
              </Fab>
              <Fab color="primary" onClick={handleUp} disabled={disableUp}>
                <ExpandLessOutlinedIcon />
              </Fab>
              <Fab
                color="primary"
                aria-label="down"
                onClick={handleDown}
                disabled={disableDown}
              >
                <ExpandMoreOutlinedIcon />
              </Fab>
              <Timeline position="right" sx={{ alignItems: "flex-start" }}>
                <TimelineItem>
                  <TimelineOppositeContent
                    color="text.secondary"
                    sx={{ flex: 0.4 }}
                  >
                    Start
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {isLogsLengthNotEqToCurrentOrLess
                      ? dateService.toTimeZone(
                          submissionData.submissionLog.logs[current - 1]
                            .timestamp
                        )
                      : dateService.toTimeZone(
                          submissionData.submissionLog.logs[
                            submissionData.submissionLog.logs.length - 2
                          ].timestamp
                        )}
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    color="text.secondary"
                    sx={{ flex: 0.4 }}
                  >
                    Time elapsed
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {isLogsLengthNotEqToCurrentOrLess
                      ? dateService.dateDiffInSecs(
                          submissionData.submissionLog.logs[current - 1]
                            .timestamp,
                          submissionData.submissionLog.logs[current].timestamp
                        )
                      : dateService.dateDiffInSecs(
                          submissionData.submissionLog.logs[
                            submissionData.submissionLog.logs.length - 2
                          ].timestamp,
                          submissionData.submissionLog.logs[
                            submissionData.submissionLog.logs.length - 1
                          ].timestamp
                        )}
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    color="text.secondary"
                    sx={{ flex: 0.4 }}
                  >
                    End
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    {isLogsLengthNotEqToCurrentOrLess
                      ? dateService.toTimeZone(
                          submissionData.submissionLog.logs[current].timestamp
                        )
                      : dateService.toTimeZone(
                          submissionData.submissionLog.logs[
                            submissionData.submissionLog.logs.length - 1
                          ].timestamp
                        )}
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
              <>
                <Legend
                  items={[
                    {
                      text: "New text",
                      color: "#008001",
                    },
                    {
                      text: "Edited text",
                      color: "#ffa500",
                    },
                    {
                      text: "Deleted text",
                      color: "#ff0000",
                    },
                  ]}
                />
              </>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default WritingProgress;
